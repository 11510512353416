.modal-wrapper {
    display: flex;
    align-items: right;
    position: absolute;
    z-index: 1000;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #ffffff;
    width: 20%;
    padding: 20px;
    margin: 20px auto;
    top: 7%;
    overflow-x: hidden;
}

.bigModal {
    height: 605px;
}

.mediumModal {
    height: 505px;
}

.littleModal {
    height: 350px;
}

.modal {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}
.modal p.info {
    text-align: center;
}

.modal-overlay {
    top: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    z-index: 999;
    position: fixed;
    overflow-y: scroll;
}

.modal-close-button {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    border-radius: 0;
    background-color: #e20118;
    border: 0;
    color: #ffffff;
    font-size: 1.2em;
    transition: 0.3s;
}

.modal-close-button:hover, .button-form:hover {
    background-color: #b40013;
}

.modal-header h2, .modal .intro {
    text-align: center;
}

.modal .intro {
    font-size: 0.9em;
}
.modal .long_intro {
    font-size: 0.7em;
}

.modal .info_temp_mdp {
    margin-bottom: 0;
    text-align: center;
    font-size: 0.9em;
}
.modal .link_expired_mdp {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 0.7em;
    display: block;
}

.changeInfos input {
    width: calc(100% - 25px);
    padding: 10px;
    margin: 5px 0px;
    border: #cccccc solid 1px;
}


.modal .captcha {
    margin: auto;
    width: 75%;
}

.button-form  {
    width: 100%;
    margin-top: 5px;
    background-color: #e20118;
    border: none;
    padding: 15px 0px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom:10px;
    transition: 0.3s;
}